<template>
  <div class="company-info base-container">
    <div class="info-main" v-if="!isEdit">
      <div class="x3 aic" style="margin-bottom: 24px;">
        <div class="dao-title">基本信息</div>
        <div>
          <el-button size="small" @click="showEdit">
            修 改
          </el-button>
        </div>
      </div>
      <div class="content">
        <div class="x h70">
          <img :src="info.logo" class="logo" v-if="info.logo" />
          <img src="../../assets/lou.png" class="logo" v-else />
          <div class="y3 height flex1">
            <p class="color6">企业名称</p>
            <p class="p">{{ info.name }}</p>
          </div>
        </div>
        <div class="grid2 h70">
          <div class="y3 height">
            <p class="color6">企业编号</p>
            <p class="p">{{ info.code }}</p>
          </div>
          <div class="y3 height">
            <p class="color6">会员类型</p>
            <p class="p">{{ enterpriseType[info.enterpriseType] }}</p>
          </div>
        </div>
        <div class="grid2 h70">
          <div class="y3 height">
            <p class="color6">联系人姓名</p>
            <p class="p">{{ info.contactsName }}</p>
          </div>
          <div class="y3 height">
            <p class="color6">联系人手机</p>
            <p class="p">{{ info.contactsPhone | formatPhone }}</p>
          </div>
        </div>
        <div class="grid2 h70">
          <div class="y3 height">
            <p class="color6">企业地址</p>
            <p class="p">{{ info.enterpriseAddress }}</p>
          </div>
          <div class="y3 height">
            <p class="color6">
              关联P+
            </p>
            <p class="p">{{ info.fmCompanyName }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-form
      class="info-main setting-info-form"
      ref="form"
      :model="formData"
      v-else
    >
      <div class="x3 aic" style="margin-bottom: 24px;">
        <div class="dao-title">基本信息</div>
        <div>
          <el-button size="small" @click="isEdit = false">
            取 消
          </el-button>
          <el-button type="primary" size="small" @click="submit">
            保 存
          </el-button>
        </div>
      </div>
      <div class="content">
        <div class="x h70">
          <img :src="info.logo" class="logo" v-if="info.logo" />
          <img src="../../assets/lou.png" class="logo" v-else />
          <div class="y3 height flex1">
            <p class="color6">企业名称</p>
            <el-input
              size="medium"
              placeholder="请输入"
              v-model="formData.name"
            ></el-input>
          </div>
        </div>
        <div class="grid2 h70">
          <div class="y3 height">
            <p class="color6">企业编号</p>
            <p class="p">{{ info.code }}</p>
          </div>
          <div class="y3 height">
            <p class="color6">会员类型</p>
            <p class="p">{{ enterpriseType[info.enterpriseType] }}</p>
          </div>
        </div>
        <div class="grid2 h70">
          <div class="y3 height">
            <p class="color6">联系人姓名</p>
            <el-input
              size="medium"
              placeholder="请输入"
              v-model="formData.contactsName"
            ></el-input>
          </div>
          <el-form-item
            class="height"
            prop="contactsPhone"
            :rules="[rules.tel]"
          >
            <p class="color6">联系人手机</p>
            <el-input
              size="medium"
              placeholder="请输入"
              v-model="formData.contactsPhone"
            ></el-input>
          </el-form-item>
        </div>
        <div class="h70">
          <div class="y3 height">
            <p class="color6">企业地址</p>
            <el-input
              size="medium"
              placeholder="请输入"
              v-model="formData.enterpriseAddress"
            ></el-input>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  getEnterpriseInfoVo,
  editEnterpriseInfo,
  createEnterpriseInfo
} from "@/api/setting";
import rules from "@/utils/rules";
const formData = {
  code: "",
  contactsName: "",
  contactsPhone: "",
  enterpriseAddress: "",
  enterpriseType: "",
  logo: "",
  name: ""
};
export default {
  data() {
    return {
      rules,
      toggleDialog: false,
      formData: JSON.parse(JSON.stringify(formData)),
      info: {},
      enterpriseType: {
        1: "普通会员",
        2: "VIP会员",
        3: "运营商会员"
      },
      isEdit: false
    };
  },
  created() {
    this.getEnterpriseInfoVo();
  },
  methods: {
    async getEnterpriseInfoVo() {
      const data = await getEnterpriseInfoVo();
      if (data) {
        this.info = data;
      }
    },
    async showEdit() {
      //点击编辑
      this.formData = this.$deepClone(this.info);
      this.isEdit = true;
    },
    async submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const fn = this.formData.id ? editEnterpriseInfo : createEnterpriseInfo;
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("提交成功");
          this.formData = this.$deepClone(formData);
          this.getEnterpriseInfoVo();
          this.isEdit = false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.setting-info-form {
  .el-form-item {
    margin-bottom: 0;
    .el-form-item__content {
      padding: 0;
      height: 70px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .el-input {
        margin-top: 12px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.company-info {
  .info-main {
    width: 730px;
    margin: 0 auto;
    padding: 48px 0;
    .content {
      padding-left: 10px;
      .h70 {
        height: 70px;
        margin-bottom: 32px;
      }
      .logo {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 16px;
      }
      .p {
        height: 36px;
        line-height: 36px;
        background: #f2f2f2;
        padding: 0 15px;
        border-radius: 5px;
      }
      .grid2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 80px;
      }
    }
  }
}
</style>
