var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-info base-container"
  }, [!_vm.isEdit ? _c('div', {
    staticClass: "info-main"
  }, [_c('div', {
    staticClass: "x3 aic",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c('div', {
    staticClass: "dao-title"
  }, [_vm._v("基本信息")]), _c('div', [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.showEdit
    }
  }, [_vm._v(" 修 改 ")])], 1)]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "x h70"
  }, [_vm.info.logo ? _c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.info.logo
    }
  }) : _c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("../../assets/lou.png")
    }
  }), _c('div', {
    staticClass: "y3 height flex1"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业名称")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.name))])])]), _c('div', {
    staticClass: "grid2 h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业编号")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.code))])]), _c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("会员类型")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.enterpriseType[_vm.info.enterpriseType]))])])]), _c('div', {
    staticClass: "grid2 h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("联系人姓名")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.contactsName))])]), _c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("联系人手机")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.info.contactsPhone)))])])]), _c('div', {
    staticClass: "grid2 h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业地址")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.enterpriseAddress))])]), _c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v(" 关联P+ ")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.fmCompanyName))])])])])]) : _c('el-form', {
    ref: "form",
    staticClass: "info-main setting-info-form",
    attrs: {
      "model": _vm.formData
    }
  }, [_c('div', {
    staticClass: "x3 aic",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c('div', {
    staticClass: "dao-title"
  }, [_vm._v("基本信息")]), _c('div', [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.isEdit = false;
      }
    }
  }, [_vm._v(" 取 消 ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 保 存 ")])], 1)]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "x h70"
  }, [_vm.info.logo ? _c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.info.logo
    }
  }) : _c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("../../assets/lou.png")
    }
  }), _c('div', {
    staticClass: "y3 height flex1"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业名称")]), _c('el-input', {
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)]), _c('div', {
    staticClass: "grid2 h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业编号")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.info.code))])]), _c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("会员类型")]), _c('p', {
    staticClass: "p"
  }, [_vm._v(_vm._s(_vm.enterpriseType[_vm.info.enterpriseType]))])])]), _c('div', {
    staticClass: "grid2 h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("联系人姓名")]), _c('el-input', {
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.contactsName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "contactsName", $$v);
      },
      expression: "formData.contactsName"
    }
  })], 1), _c('el-form-item', {
    staticClass: "height",
    attrs: {
      "prop": "contactsPhone",
      "rules": [_vm.rules.tel]
    }
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("联系人手机")]), _c('el-input', {
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.contactsPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "contactsPhone", $$v);
      },
      expression: "formData.contactsPhone"
    }
  })], 1)], 1), _c('div', {
    staticClass: "h70"
  }, [_c('div', {
    staticClass: "y3 height"
  }, [_c('p', {
    staticClass: "color6"
  }, [_vm._v("企业地址")]), _c('el-input', {
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.enterpriseAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "enterpriseAddress", $$v);
      },
      expression: "formData.enterpriseAddress"
    }
  })], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }